/** Notifies the parent that the iframe request scroll to exposure iframe position */
const exposureIframeScrollToIframe = () => {
  window.parent.postMessage(
    {
      call: 'exposureIframeScrollToIframe',
    },
    '*'
  );
};

export default exposureIframeScrollToIframe;
