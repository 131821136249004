import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import exposureIframeHeight from 'utils/exposureIframeHeight';
import exposureIframeReady from 'utils/exposureIframeReady';
import hivebriteReady from 'utils/hivebriteReady';
import jwtReady from 'utils/jwtReady';
import { useResize, useAppDispatch } from 'hooks';

import { setUser } from 'redux/userSlice';

import PagePublicHome from 'pages/PublicHome';
import PageAbout from 'pages/About';
import PageArchitects from 'pages/Architects';
import PageError from 'pages/Error';

import 'App.css';
import PageCourse from 'pages/Course';
import { parseJwt } from 'utils/parseJwt';
import 'App.css';
import * as strapi from 'services/strapi';

function App() {
  const dispatch = useAppDispatch();

  useResize(exposureIframeHeight, 250);

  useEffect(() => {
    function init() {
      jwtReady(async (jwt) => {
        strapi.setJwtToken(jwt);
        const user = await strapi.getOrCreateHivebriteUser(parseJwt(jwt));
        user && dispatch(setUser(user));
      });
      exposureIframeReady();
      exposureIframeHeight();
    }

    hivebriteReady(init);
    init();
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<PagePublicHome />} />
        <Route path="/public-homepage" element={<PagePublicHome />} />
        <Route path="/about" element={<PageAbout />} />
        <Route path="/architects" element={<PageArchitects />} />
        <Route path="/courses/:slug" element={<PageCourse />} />
        <Route path="*" element={<PageError />} />
      </Routes>
    </Router>
  );
}

export default App;
