import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactMarkdown from 'react-markdown';
import * as strapi from 'services/strapi';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { fileIsVideo, getImageFormat } from '@exposuredigital/strapi-helpers';
import Plyr, { PlyrProps } from 'plyr-react';
import 'assets/css/plyr/plyr.css';
import PageError from 'pages/Error';
import exposureIframeHeight from 'utils/exposureIframeHeight';
import exposureIframeJoinUser from 'utils/exposureIframeJoinUser';
import exposureIframeScrollToIframe from 'utils/exposureIframeScrollToIframe';
import AdditionalContent from 'components/sections/AdditionalContent';
import StrapiImage from 'components/shared/StrapiImage';
import FeatureList from 'components/sections/FeatureList';
import { getUser, setUser, userIsLoggedIn } from 'redux/userSlice';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import {
  IAdditionalContentItem,
  ICourse,
  ISingleModule,
} from 'types/generated/strapi';
import VideoPoster from 'assets/img/video-poster.jpg';
import styles from './Course.module.css';
import exposureIframeShowModalLogin from 'utils/exposureIframeShowModalLogin';
import { PageLoading } from 'components/shared/PageLoading';
import CourseModules from 'components/sections/CourseModules';
import SingleModule from 'components/shared/SingleModule';
import { ITracking } from 'types/tracking';
import { IStream } from 'types/stream';
import { download } from 'utils/download';
import Stream from 'components/shared/Stream';
import { createTracks } from 'utils/createTracks';

const PageCourse: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);

  const [pageData, setPageData] = useState<ICourse>();

  const [activeCourseModule, setActiveCourseModule] = useState<ISingleModule>();
  const [activeStream, setActiveStream] = useState<IStream>();

  const isUserLoggedIn = useAppSelector(userIsLoggedIn);
  const user = useAppSelector(getUser);

  const { slug } = useParams();

  const {
    id: courseId,
    title,
    overview,
    overviewMedia,
    overviewVideoCaptions,
    creatorName,
    featureList,
    modules,
    additionalContent,
    thumbnail,
    courseFeatures,
    livestreams,
    completedCourseBadge,
    completedCourseBadgeTitle,
    completedCourseBadgeCopy,
  } = {
    ...pageData,
  };

  const userTracking = user?.tracking as ITracking | undefined;
  const userCourse = userTracking?.courses?.find((c) => c.id === courseId);
  const modulesSectionItems = useMemo(() => modules?.modules || [], [modules]);

  useEffect(() => {
    (async () => {
      if (!slug) return;
      const pageData = await strapi.getCourse(slug);

      setPageData(pageData);
      setPageIsLoading(false);
      exposureIframeHeight();
    })();
  }, [slug]);

  const overviewMediaIsVideo = useMemo(
    () => fileIsVideo(overviewMedia),
    [overviewMedia]
  );

  const onBackClick = useCallback(() => {
    setActiveCourseModule(undefined);
    setActiveStream(undefined);
  }, []);

  const setModule = useCallback((module: ISingleModule) => {
    setActiveStream(undefined);
    setActiveCourseModule(module);
  }, []);

  const onAdditionalContentItemClick = useCallback(
    (additionalContentItem: IAdditionalContentItem) => {
      const { youTubeLink, title } = additionalContentItem;
      if (youTubeLink && title) {
        setActiveStream({
          title,
          youTubeLink,
        });
        setActiveCourseModule(undefined);
      }
    },
    []
  );

  // On course module change recalc iframe height and set scrolltop
  useEffect(() => {
    exposureIframeHeight();
    exposureIframeScrollToIframe();
  }, [activeCourseModule, activeStream]);

  const dispatch = useAppDispatch();
  const updatingUser = useRef(false);

  // Plyr settings
  const plyrSource: PlyrProps['source'] = useMemo(() => {
    if (!overviewMedia || !overviewMediaIsVideo) return;

    const tracks = createTracks(overviewVideoCaptions);

    return {
      type: 'video',
      sources: [
        {
          src: overviewMedia.url,
          type: overviewMedia.mime,
        },
      ],
      poster: thumbnail
        ? getImageFormat({ file: thumbnail, format: 'large' })
        : VideoPoster,
      tracks,
    };
  }, [overviewMedia, thumbnail, overviewMediaIsVideo, overviewVideoCaptions]);

  // Plyr settings
  const plyrOptions: PlyrProps['options'] = useMemo(() => {
    return {
      controls: [
        'play-large',
        'play',
        'progress',
        'mute',
        'volume',
        'settings',
        'captions',
        'fullscreen',
      ],
      ratio: '16:9',
      settings: ['captions'],
    };
  }, []);

  const activeModuleIndex = useMemo(() => {
    if (!modulesSectionItems.length) return -1;
    return modulesSectionItems.findIndex(
      (c) => c.id === activeCourseModule?.id
    );
  }, [modulesSectionItems, activeCourseModule]);

  const onNextClick = useCallback(() => {
    if (!modulesSectionItems.length || activeModuleIndex === -1) return;
    const nextModule = modulesSectionItems[activeModuleIndex + 1];
    setActiveCourseModule(nextModule);
  }, [modulesSectionItems, activeModuleIndex]);

  const onPrevClick = useCallback(() => {
    if (!modulesSectionItems.length || activeModuleIndex === -1) return;
    const prevModule = modulesSectionItems[activeModuleIndex - 1];
    setActiveCourseModule(prevModule);
  }, [modulesSectionItems, activeModuleIndex]);

  const withNextButton = useMemo(() => {
    if (!modulesSectionItems.length || activeModuleIndex === -1) return false;
    const nextModule = modulesSectionItems[activeModuleIndex + 1];
    return nextModule ? true : false;
  }, [modulesSectionItems, activeModuleIndex]);

  const withPrevButton = useMemo(() => {
    if (!modulesSectionItems.length || activeModuleIndex === -1) return false;
    const prevModule = modulesSectionItems[activeModuleIndex - 1];
    return prevModule ? true : false;
  }, [modulesSectionItems, activeModuleIndex]);

  const onStartCourseClick = () => {
    if (!modulesSectionItems.length) return;
    exposureIframeJoinUser();
    setActiveCourseModule(modulesSectionItems[0]);
  };

  const hasUserStartedAnyModuleOfCourse = userCourse?.modules?.some(
    (module) => !!module.completedChunks.length
  );

  const hasUserCompletedAllModuleOfCourse =
    !!userCourse?.modules?.length &&
    userCourse?.modules.filter((module) => module.completedAt).length ===
      modulesSectionItems.length;

  const hasUserCompletedCourse = !!userCourse?.completedAt;

  const completeUserCourse = useCallback(async () => {
    if (hasUserCompletedCourse || updatingUser.current || !user || !courseId)
      return;

    updatingUser.current = true;
    const updatedUser = await strapi.completeCourseByUser({
      user,
      courseId,
      date: Date.now(),
    });
    updatingUser.current = false;
    updatedUser && dispatch(setUser(updatedUser));
  }, [user, dispatch, courseId, hasUserCompletedCourse]);

  // Complete course if all modules completed
  useEffect(() => {
    if (!hasUserCompletedAllModuleOfCourse) return;
    completeUserCourse();
  }, [hasUserCompletedAllModuleOfCourse, completeUserCourse]);

  const badgeUrl = !completedCourseBadge
    ? '/course-completed-badge.png'
    : completedCourseBadge.url;
  // Error
  if (!pageIsLoading && !pageData) return <PageError />;

  // Loading
  if (pageIsLoading) return <PageLoading />;

  // Module
  if (activeCourseModule && courseId && title && isUserLoggedIn)
    return (
      <>
        {/* Course module */}
        <SingleModule
          onBackClick={onBackClick}
          onNextClick={onNextClick}
          onPrevClick={onPrevClick}
          withNextButton={withNextButton}
          withPrevButton={withPrevButton}
          data={activeCourseModule}
          courseId={courseId}
          courseTitle={title}
        />
        {/* Course modules */}
        {modules && (
          <CourseModules
            data={modules}
            onModuleClick={setModule}
            activeModule={activeCourseModule}
            courseId={courseId}
          />
        )}
      </>
    );

  // Stream
  if (activeStream && courseId && isUserLoggedIn)
    return (
      <>
        {/* Stream */}
        <Stream onBackClick={onBackClick} data={activeStream} />
        {/* Course modules */}
        {modules && (
          <CourseModules
            data={modules}
            onModuleClick={setModule}
            courseId={courseId}
          />
        )}
      </>
    );

  // Course
  return (
    <>
      <div className={styles.courseHeader} style={{ zIndex: 3 }}>
        <div className={styles.courseHeaderInner}>
          {overviewMedia && (
            <div className={styles.mediaWrapper}>
              {overviewMediaIsVideo ? (
                <div>
                  <Plyr
                    source={plyrSource}
                    options={plyrOptions}
                    crossOrigin="anonymus"
                  />
                </div>
              ) : (
                <StrapiImage image={overviewMedia} format="large" />
              )}
              {courseFeatures && (
                <ReactMarkdown
                  children={courseFeatures}
                  className={styles.courseFeatures}
                />
              )}
            </div>
          )}
          <div className={styles.courseTextColumn}>
            <h2 className={styles.courseTitle}>{title}</h2>
            <div className={styles.courseOverview}>{overview}</div>
            {creatorName && (
              <div className={styles.courseCreatorName}>
                Created by {creatorName}
              </div>
            )}
            {!isUserLoggedIn && (
              <button
                className={styles.courseLoginToView}
                onClick={() => exposureIframeShowModalLogin()}
              >
                Login to get access to course material
              </button>
            )}
            {isUserLoggedIn &&
              !hasUserCompletedAllModuleOfCourse &&
              hasUserStartedAnyModuleOfCourse && (
                <span className={styles.courseInProgress}>
                  Course in progress
                </span>
              )}
            {isUserLoggedIn && hasUserCompletedAllModuleOfCourse && (
              <button
                className={styles.badgeDownloadButton}
                onClick={() => download(badgeUrl, 'course-completed-badge.png')}
              >
                <div className={styles.badgeImageWrapper}>
                  <img src={badgeUrl} alt="Course Completed" />
                </div>
                <div className={styles.badgeTextWrapper}>
                  {completedCourseBadgeTitle && (
                    <div className={styles.badgeTitle}>
                      {completedCourseBadgeTitle}
                    </div>
                  )}
                  {completedCourseBadgeCopy && (
                    <ReactMarkdown
                      children={completedCourseBadgeCopy}
                      className={styles.badgeDescription}
                    />
                  )}
                </div>
              </button>
            )}
            {isUserLoggedIn && !hasUserStartedAnyModuleOfCourse && (
              <button
                className={styles.courseLoginToView}
                onClick={onStartCourseClick}
              >
                Start course
              </button>
            )}

            {courseFeatures && (
              <ReactMarkdown
                children={courseFeatures}
                className={styles.courseFeaturesMobile}
              />
            )}
          </div>
        </div>
      </div>
      {/* Feature List */}
      {featureList && <FeatureList data={featureList} />}

      {/* No pointer events for guest */}
      <div
        className={clsx(
          process.env.NODE_ENV !== 'development' &&
            !isUserLoggedIn &&
            styles.guestMode
        )}
      >
        {/* Course modules */}
        {modules && courseId && (
          <CourseModules
            data={modules}
            onModuleClick={setModule}
            courseId={courseId}
          />
        )}

        {/* Additonal Content */}
        {additionalContent && isUserLoggedIn && (
          <AdditionalContent data={additionalContent} />
        )}

        {/* Livestreams */}
        {livestreams && isUserLoggedIn && (
          <AdditionalContent
            onAdditionalContentItemClick={onAdditionalContentItemClick}
            data={livestreams}
          />
        )}
      </div>
    </>
  );
};

export default PageCourse;
