import { FunctionComponent, useEffect, useState } from 'react';
import * as strapi from 'services/strapi';
import exposureIframeHeight from 'utils/exposureIframeHeight';
import CoursesList from 'components/sections/CoursesList';
import ImageAndText from 'components/sections/ImageAndText';
import TeaserGrid from 'components/sections/TeaserGrid';
import PageError from 'pages/Error';
import { IPagePublicHome } from 'types/generated/strapi';
import { PageLoading } from 'components/shared/PageLoading';
import TextOverImage from 'components/sections/TextOverImage';

const PagePublicHome: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);
  const [pageData, setPageData] = useState<IPagePublicHome>();

  useEffect(() => {
    (async () => {
      const pageData = await strapi.getPagePublicHome();

      setPageData(pageData);
      setPageIsLoading(false);
      exposureIframeHeight();
    })();
  }, []);

  const { header, coursesList, about, teaserGrid } = { ...pageData };

  if (!pageIsLoading && !pageData) return <PageError />;

  return pageIsLoading ? (
    <PageLoading />
  ) : (
    <>
      {header && <TextOverImage data={header} showCreateAccountButton={true} />}
      {teaserGrid && <TeaserGrid data={teaserGrid} />}
      {coursesList && <CoursesList data={coursesList} />}
      {about && <ImageAndText data={about} />}
    </>
  );
};

export default PagePublicHome;
