/** Executes a callback when 'hivebriteReady' is received */
const hivebriteReady = (callback: () => void) => {
  window.addEventListener(
    'message',
    (event) => {
      if (event.data.call === 'hivebriteReady') {
        callback();
      }
    },
    false
  );
};

export default hivebriteReady;
