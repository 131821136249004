import { FunctionComponent, useEffect, useState } from 'react';
import * as strapi from 'services/strapi';
import exposureIframeHeight from 'utils/exposureIframeHeight';
import SingleImage from 'components/sections/SingleImage';
import ImageAndTwoColumnText from 'components/sections/ImageAndTwoColumnText';
import ImageAndText from 'components/sections/ImageAndText';
import TeaserGrid from 'components/sections/TeaserGrid';
import PageError from 'pages/Error';
import { IPageAbout } from 'types/generated/strapi';
import { PageLoading } from 'components/shared/PageLoading';

const PageHome: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);
  const [pageData, setPageData] = useState<IPageAbout>();

  useEffect(() => {
    (async () => {
      const pageData = await strapi.getPageAbout();

      setPageData(pageData);
      setPageIsLoading(false);
      exposureIframeHeight();
    })();
  }, []);

  const { headerImage, intro, about, teaserGrid } = { ...pageData };

  if (!pageIsLoading && !pageData) return <PageError />;

  return pageIsLoading ? (
    <PageLoading />
  ) : (
    <>
      {headerImage && <SingleImage data={headerImage} />}
      {intro && <ImageAndTwoColumnText data={intro} />}
      {teaserGrid && <TeaserGrid data={teaserGrid} />}
      {about && <ImageAndText data={about} />}
    </>
  );
};

export default PageHome;
