import { IOversizedTitle } from 'types/generated/strapi';
import styles from './index.module.css';

interface OversizedTitleProps {
  data: IOversizedTitle;
}

const OversizedTitle = (props: OversizedTitleProps) => {
  const { title, subtitle } = props.data;

  return (
    <div className={styles.oversizedTitle}>
      <div className={styles.oversizedTitleInner}>
        <h2 className={styles.title}>{title}</h2>
        <h3 className={styles.subtitle}>{subtitle}</h3>
      </div>

      {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
    </div>
  );
};

export default OversizedTitle;
