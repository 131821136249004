import { FunctionComponent } from 'react';
import { IFeatureList } from 'types/generated/strapi';
import styles from './index.module.css';

interface FeatureListProps {
  data: IFeatureList;
}

const FeatureList: FunctionComponent<FeatureListProps> = (props) => {
  const { title, column1, column2 } = {
    ...props.data,
  };

  return (
    <div className={styles.featureList}>
      <div className={styles.featureListInner}>
        {/* Title */}
        {title && <h2 className={styles.title}>{title}</h2>}
        <div className={styles.featuresWrapper}>
          {!!column1.length && (
            <ul className={styles.features}>
              {column1.map((item) => (
                <li className={styles.featureItem} key={item.id}>
                  {item.feature}
                </li>
              ))}
            </ul>
          )}
          {!!column2.length && (
            <ul className={styles.features}>
              {column2.map((item) => (
                <li className={styles.featureItem} key={item.id}>
                  {item.feature}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeatureList;
