import styles from './index.module.css';
import { ReactComponent as PlayIcon } from 'assets/svg/play.svg';
import { ReactComponent as DocumentIcon } from 'assets/svg/document.svg';
import { ReactComponent as DoneIcon } from 'assets/svg/done.svg';
import { ISingleModule, ICourseModules, IFile } from 'types/generated/strapi';
import clsx from 'clsx';
import { useAppSelector } from 'hooks/store';
import { getUser } from 'redux/userSlice';
import { useCallback } from 'react';
import { ITracking } from 'types/tracking';

const ModuleProgress = (props: {
  completedChunks: number[];
  progress: number;
}) => {
  const { completedChunks, progress } = props;
  // Get all chunks from progress and completed chunks
  const allChunksLength = (completedChunks.length * 100) / progress;

  // Generate a sequence of numbers
  // [0, 1, 2, 3, 4]
  const allChunksArray = Array.from({ length: allChunksLength }, (v, i) => i);

  return (
    <div className={styles.moduleProgress}>
      <div className={styles.moduleProgressValue}>{progress}%</div>
      <div className={styles.moduleProgressBar}>
        {allChunksArray.map((chunk, index) => {
          const completed = completedChunks.includes(chunk);
          return (
            <div
              key={index}
              className={clsx(
                styles.moduleProgressChunk,
                completed && styles.moduleProgressChunkCompleted
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

interface IWorkbookProps {
  file: IFile;
  label?: string;
}
const Workbook = (props: IWorkbookProps) => {
  const { file, label } = props;
  const user = useAppSelector(getUser);

  return (
    <div className={styles.row}>
      <div className={styles.column1}>
        {!!user && <DocumentIcon className={styles.courseModuleIcon} />}
      </div>
      <div className={styles.column2}>
        {!!user && (
          <a
            className={styles.moduleWorkbook}
            href={file.url}
            download
            target="_blank"
            rel="noreferrer"
          >
            {label || 'Workbook'}
          </a>
        )}
        {!user && (
          <span className={styles.moduleWorkbook}>{label || 'Workbook'}</span>
        )}
      </div>
      <div className={styles.column3}></div>
    </div>
  );
};

interface ICourseModuleProps {
  data: ISingleModule;
  active: boolean;
  completed: boolean;
  onClick: (param: ISingleModule) => void;
  progress?: number;
  completedChunks: number[];
}
const CourseModule = (props: ICourseModuleProps) => {
  const {
    title,
    duration,
    workbook1,
    workbook1Label,
    workbook2,
    workbook2Label,
    workbook3,
    workbook3Label,
    workbook4,
    workbook4Label,
    workbook5,
    workbook5Label,
  } = props.data;
  const { onClick, active, completed, progress, completedChunks } = props;
  const user = useAppSelector(getUser);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.column1}>
          {/* Not started */}
          {!!user && !progress && (
            <PlayIcon className={styles.courseModuleIcon} />
          )}
          {/* Progress */}
          {!!user && progress && !completed && (
            <ModuleProgress
              completedChunks={completedChunks}
              progress={progress}
            />
          )}
          {/* Done */}
          {!!user && completed && <DoneIcon className={styles.doneIcon} />}
        </div>
        <div className={styles.column2}>
          <button
            className={clsx(
              styles.moduleTitle,
              active && styles.moduleTitleActive
            )}
            onClick={() => onClick(props.data)}
          >
            {title}
          </button>
        </div>
        <div className={styles.column3}>
          <span className={styles.courseModuleDuration}>{duration}</span>
        </div>
      </div>
      {/* Module Workbooks */}
      {workbook1 && <Workbook file={workbook1} label={workbook1Label} />}
      {workbook2 && <Workbook file={workbook2} label={workbook2Label} />}
      {workbook3 && <Workbook file={workbook3} label={workbook3Label} />}
      {workbook4 && <Workbook file={workbook4} label={workbook4Label} />}
      {workbook5 && <Workbook file={workbook5} label={workbook5Label} />}
    </>
  );
};

interface ICourseModulesProps {
  data: ICourseModules;
  onModuleClick: (module: ISingleModule) => void;
  activeModule?: ISingleModule;
  courseId: number;
}
const CourseModules = (props: ICourseModulesProps) => {
  const { modules, title, subtitle, workbook } = props.data;
  const { onModuleClick, activeModule, courseId } = props;
  const user = useAppSelector(getUser);
  const userTracking = user?.tracking as ITracking;
  const userCourse = userTracking?.courses?.find((c) => c.id === courseId);

  const isModuleActive = useCallback(
    (module: ISingleModule) => activeModule?.id === module.id,
    [activeModule]
  );

  const isModuleCompleted = useCallback(
    (module: ISingleModule) => {
      return (
        !!user &&
        !!userCourse?.modules?.find((cm) => cm.id === module.id)?.completedAt
      );
    },
    [user, userCourse]
  );

  const getModuleProgress = useCallback(
    (module: ISingleModule) => {
      return userCourse?.modules?.find((cm) => cm.id === module.id)
        ?.completedPercentage;
    },
    [userCourse]
  );

  const getModuleCompletedChunks = useCallback(
    (module: ISingleModule) => {
      return (
        userCourse?.modules?.find((cm) => cm.id === module.id)
          ?.completedChunks || []
      );
    },
    [userCourse]
  );

  return (
    <div className={styles.courseModules}>
      <div className={styles.courseModulesInner}>
        <div className={styles.header}>
          {title && <h2 className={styles.title}>{title}</h2>}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
        <div className={styles.tableWrapper}>
          <div className={styles.table}>
            {modules.map((module) => (
              <CourseModule
                key={module.id}
                data={module}
                onClick={onModuleClick}
                active={isModuleActive(module)}
                completed={isModuleCompleted(module)}
                progress={getModuleProgress(module)}
                completedChunks={getModuleCompletedChunks(module)}
              />
            ))}
            {workbook && (
              <div className={styles.row}>
                <div className={styles.column1}>
                  {user && <DocumentIcon className={styles.courseModuleIcon} />}
                </div>
                <div className={styles.column2}>
                  {user && (
                    <a
                      className={styles.moduleWorkbook}
                      href={workbook.url}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      Course workbook
                    </a>
                  )}
                  {!user && (
                    <span className={styles.moduleWorkbook}>
                      Course workbook
                    </span>
                  )}
                </div>
                <div className={styles.column3}></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseModules;
