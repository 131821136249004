import { Hivebrite } from 'types/Hivebrite';
import {
  IPageAbout,
  IPageArchitects,
  ICourse,
  IPagePublicHome,
  IArchitect,
  IHivebriteUser,
} from '../types/generated/strapi';
import merge from 'lodash.merge';
import { ITracking } from 'types/tracking';
import {
  createTracking,
  createTrackingCourse,
  createTrackingCourseModule,
} from 'utils/tracking';

let jwt: string;

/**
 * =============================================================================
 *  Helpers
 * =============================================================================
 */

/**
 * -----------------------------------------------------------------------------
 *  Get Strapi media URL
 *  Strapi in production uses s3. Strapi local uses uploads.
 * -----------------------------------------------------------------------------
 */

export function getStrapiMediaUrl(path: string): string {
  if (process.env.NODE_ENV === 'production') return path;

  const cmsUrl = getStrapiURL('');
  return cmsUrl.includes('localhost') ? cmsUrl + path : path;
}

/**
 * -----------------------------------------------------------------------------
 *  Get Strapi URL from environment
 * -----------------------------------------------------------------------------
 */

function getStrapiURL(path: string): string {
  return `${
    process.env.REACT_APP_CMS_URL ||
    'https://adidas-community-cms.exposuredigital.com'
  }${path}`;
}

/**
 * -----------------------------------------------------------------------------
 * Set Jwt token
 * -----------------------------------------------------------------------------
 */
export function setJwtToken(token: string) {
  jwt = token;
}

/**
 * -----------------------------------------------------------------------------
 *  Fetch from Strapi API wrapper
 * -----------------------------------------------------------------------------
 */

async function fetchAPI<T>(path: string, options = {}): Promise<T> {
  const defaultOptions = {
    headers: {
      'content-type': 'application/json',
    },
  };

  const defaultOptionsWithToken = {
    headers: {
      'content-type': 'application/json',
      'x-hivebrite': jwt,
    },
  };

  const finalDefault = jwt ? defaultOptionsWithToken : defaultOptions;
  const mergedOptions = merge(finalDefault, options);

  const requestUrl = getStrapiURL(path);
  const response = await fetch(requestUrl, mergedOptions);

  if (!response.ok) {
    console.error(response.statusText);
    throw new Error(`An error occurred please try again`);
  }

  const data = await response.json();

  return data;
}

/**
 * =============================================================================
 *  Page – Public Home
 * =============================================================================
 */

export async function getPagePublicHome() {
  try {
    return await fetchAPI<IPagePublicHome>(`/page-public-homepage`);
  } catch (error) {}
}

/**
 * =============================================================================
 *  Page – About
 * =============================================================================
 */

export async function getPageAbout() {
  try {
    return await fetchAPI<IPageAbout>(`/page-about`);
  } catch (error) {}
}

/**
 * =============================================================================
 *  Page – Architects
 * =============================================================================
 */

export async function getPageArchitects() {
  try {
    const page = await fetchAPI<IPageArchitects>(`/page-architects`);
    if (page.architectsList) {
      const ids = page.architectsList?.architects.map((a) => a.id) || [];
      const query = ids.map((id) => `id_in=${id}`).join('&');
      const architects = await fetchAPI<IArchitect[]>(`/architects?${query}`);
      if (architects) page.architectsList.architects = architects;
    }

    return page;
  } catch (error) {}
}

/**
 * =============================================================================
 *  Courses
 * =============================================================================
 */

export async function getCourses() {
  try {
    return await fetchAPI<ICourse[]>(`/courses?_limit=-1`);
  } catch (error) {}
}

export async function getCourse(slug: string) {
  try {
    const coursesBySlug = await fetchAPI<ICourse[]>(`/courses?slug=${slug}`);
    return coursesBySlug.length ? coursesBySlug[0] : undefined;
  } catch (error) {}
}

/**
 * =============================================================================
 *  Hivebrite Users
 * =============================================================================
 */

export async function getOrCreateHivebriteUser(user: Hivebrite.User) {
  try {
    const exist = await getHivebriteUser(user.id);
    if (exist) return exist;
    const createNew = await createHivebriteUser(user);
    if (createNew) return createNew;
  } catch (error) {}
}

export async function createHivebriteUser(user: Hivebrite.User) {
  const hivebriteUser: Omit<IHivebriteUser, 'id' | 'completedModules'> = {
    hivebriteId: user.id,
    hivebriteFullName: user.name,
    hivebriteEmail: user.primary_email,
    tracking: createTracking({ courses: [] }),
  };

  try {
    return await fetchAPI<IHivebriteUser>(`/hivebrite-users`, {
      method: 'POST',
      body: JSON.stringify(hivebriteUser),
    });
  } catch (error) {
    console.log(error);
  }
}

export async function getHivebriteUser(hivebriteId: number) {
  try {
    const data = await fetchAPI<IHivebriteUser[]>(
      `/hivebrite-users/?hivebriteId=${hivebriteId}`
    );

    return data && data.length > 0 ? data[0] : undefined;
  } catch (error) {}
}

export async function completeUserModuleChunk({
  user,
  moduleId,
  courseId,
  moduleTitle,
  courseTitle,
  completedChunks,
  completedPercentage,
  date,
}: {
  user: IHivebriteUser;
  moduleId: number;
  courseId: number;
  moduleTitle: string;
  courseTitle: string;
  completedChunks: number[];
  completedPercentage: number;
  date: number;
}) {
  let userTracking = JSON.parse(JSON.stringify(user.tracking)) as
    | ITracking
    | undefined;
  const course = userTracking?.courses?.find((c) => c.id === courseId);
  const module = course?.modules?.find((c) => c.id === moduleId);

  // No tracking
  if (!userTracking) {
    userTracking = createTracking({
      courses: [
        createTrackingCourse({
          id: courseId,
          title: courseTitle,
          startedAt: date,
          modules: [
            createTrackingCourseModule({
              id: moduleId,
              title: moduleTitle,
              completedChunks,
              completedPercentage,
              startedAt: date,
            }),
          ],
        }),
      ],
    });
    // No current course in courses
  } else if (!course) {
    userTracking.courses.push(
      createTrackingCourse({
        id: courseId,
        title: courseTitle,
        startedAt: date,
        modules: [
          createTrackingCourseModule({
            id: moduleId,
            title: moduleTitle,
            startedAt: date,
            completedChunks,
            completedPercentage,
          }),
        ],
      })
    );
    // No current module in course
  } else if (!module) {
    course.modules.push(
      createTrackingCourseModule({
        id: moduleId,
        title: moduleTitle,
        startedAt: date,
        completedChunks,
        completedPercentage,
      })
    );
    // With current module in course
  } else {
    module.completedPercentage = completedPercentage;
    module.completedChunks = completedChunks;
    if (!module.startedAt) module.startedAt = date;
  }

  try {
    return await fetchAPI<IHivebriteUser>(`/hivebrite-users/${user.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        tracking: userTracking,
      }),
    });
  } catch (error) {}
}

export async function completeUserModule({
  user,
  moduleId,
  courseId,
  date,
}: {
  user: IHivebriteUser;
  moduleId: number;
  courseId: number;
  date: number;
}) {
  let userTracking = JSON.parse(JSON.stringify(user.tracking)) as
    | ITracking
    | undefined;
  const course = userTracking?.courses?.find((c) => c.id === courseId);
  const module = course?.modules?.find((c) => c.id === moduleId);

  if (!module) return;

  module.completedAt = date;
  module.completedPercentage = 100;

  try {
    return await fetchAPI<IHivebriteUser>(`/hivebrite-users/${user.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        tracking: userTracking,
      }),
    });
  } catch (error) {}
}

export async function completeCourseByUser({
  user,
  courseId,
  date,
}: {
  user: IHivebriteUser;
  courseId: number;
  date: number;
}) {
  const userTracking = JSON.parse(JSON.stringify(user.tracking)) as
    | ITracking
    | undefined;
  const course = userTracking?.courses?.find((c) => c.id === courseId);

  if (!course) return;

  course.completedAt = date;

  try {
    return await fetchAPI<IHivebriteUser>(`/hivebrite-users/${user.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        tracking: userTracking,
      }),
    });
  } catch (error) {}
}
