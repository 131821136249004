import { FunctionComponent } from 'react';
import StrapiImage from 'components/shared/StrapiImage';
import { IArchitectsList } from 'types/generated/strapi';
import styles from './index.module.css';
import { ReactComponent as TwitterIcon } from 'assets/svg/twitter.svg';
import { ReactComponent as InstagramIcon } from 'assets/svg/instagram.svg';
import { ReactComponent as TiktokIcon } from 'assets/svg/tiktok.svg';
import { ReactComponent as LinkedinIcon } from 'assets/svg/linkedin.svg';
import { ReactComponent as WebsiteIcon } from 'assets/svg/globe.svg';
import ReactMarkdown from 'react-markdown';

interface ArchitectsListProps {
  data: IArchitectsList;
}

const ArchitectsList: FunctionComponent<ArchitectsListProps> = (props) => {
  const { architects } = props.data;

  return (
    <div className={styles.architectsList}>
      <div className={styles.architectsListInner}>
        <div className={styles.architects}>
          {architects.map((architect, architectIndex) => {
            const { image, name, discipline, biography, socialMediaLinks } =
              architect;

            return (
              <div
                className={styles.architect}
                key={`architect-${architectIndex}`}
              >
                {/* Image */}
                <div className={styles.architectImageWrapper}>
                  <StrapiImage
                    className={styles.architectImage}
                    image={image}
                    format="small"
                  />
                </div>

                {/* Name */}
                <div className={styles.architectName}>{name}</div>

                {/* Discipline */}
                {discipline && (
                  <div className={styles.architectDiscipline}>{discipline}</div>
                )}

                {/* Biography */}
                {biography && (
                  <ReactMarkdown
                    children={biography}
                    className={styles.architectBio}
                    components={{
                      a: ({ node, ...props }) => (
                        <a {...props} target="_blank" rel="noreferrer">
                          {props.children}
                        </a>
                      ),
                    }}
                  />
                )}

                {/* Social media */}
                {socialMediaLinks?.length > 0 && (
                  <ul className={styles.architectSocial}>
                    {socialMediaLinks.map(
                      (socialMediaLink, socialMediaLinkIndex) => {
                        const { url, icon } = socialMediaLink;

                        return (
                          <li key={`social-${socialMediaLinkIndex}`}>
                            <a href={url} target="_blank" rel="noreferrer">
                              {icon === 'Twitter' && (
                                <TwitterIcon className={styles.socialIcon} />
                              )}
                              {icon === 'Instagram' && (
                                <InstagramIcon className={styles.socialIcon} />
                              )}
                              {icon === 'Linkedin' && (
                                <LinkedinIcon className={styles.socialIcon} />
                              )}
                              {icon === 'Tiktok' && (
                                <TiktokIcon className={styles.socialIcon} />
                              )}
                              {icon === 'Website' && (
                                <WebsiteIcon className={styles.socialIcon} />
                              )}
                            </a>
                          </li>
                        );
                      }
                    )}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
    </div>
  );
};

export default ArchitectsList;
