// We can "download" only from current location
// Download image from external location.
// Add it as blob in url to anchor element with download attribute. Fire click event.
export const download = (url: string, name: string) => {
  if (!url) {
    throw new Error('Resource URL not provided! You need to provide one');
  }
  fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobURL;
      a.style.display = 'none';

      if (name && name.length) a.download = name;
      document.body.appendChild(a);
      a.click();
    })
    .catch((e) => console.log(e));
};
