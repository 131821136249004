import StrapiImage from 'components/shared/StrapiImage';
import { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { ISingleImage } from 'types/generated/strapi';
import styles from './index.module.css';
import { fileIsVideo } from '@exposuredigital/strapi-helpers';
import exposureIframeHeight from 'utils/exposureIframeHeight';

interface SingleImageProps {
  data: ISingleImage;
}

const SingleImage: FunctionComponent<SingleImageProps> = (props) => {
  const { fullWidth, media } = props.data;
  const videoRef = useRef<HTMLVideoElement>(null);

  const mediaIsVideo = useMemo(() => {
    return fileIsVideo(media);
  }, [media]);

  const onVideoLoadedData = useCallback(() => {
    if (!videoRef.current) return;

    if (videoRef.current.readyState >= 3) {
      exposureIframeHeight();
    }
  }, []);

  return (
    <div className={clsx([!fullWidth && styles.singleImage])}>
      <div className={clsx([!fullWidth && styles.singleImageInner])}>
        {mediaIsVideo && (
          <video
            className={styles.media}
            src={media.url}
            muted={true}
            autoPlay={true}
            loop={true}
            onLoadedData={onVideoLoadedData}
            ref={videoRef}
          />
        )}
        {!mediaIsVideo && (
          <StrapiImage className={styles.media} image={media} format="xlarge" />
        )}
      </div>

      {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
    </div>
  );
};

export default SingleImage;
