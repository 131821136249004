/** Notifies the parent that the iframe request to show modal with modal */
const exposureIframeShowModalLogin = () => {
  window.parent.postMessage(
    {
      call: 'exposureIframeShowModalLogin',
    },
    '*'
  );
};

export default exposureIframeShowModalLogin;
