import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { getImageFormat } from '@exposuredigital/strapi-helpers';
import Plyr, { APITypes, PlyrProps } from 'plyr-react';
import 'assets/css/plyr/plyr.css';

import { ISingleModule } from 'types/generated/strapi';

import VideoPoster from 'assets/img/video-poster.jpg';
import styles from './index.module.css';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getUser, setUser } from 'redux/userSlice';
import * as strapi from 'services/strapi';
import { ITracking } from 'types/tracking';
import { createTracks } from 'utils/createTracks';

interface ISingleModuleProps {
  data: ISingleModule;
  onBackClick: () => void;
  onNextClick: () => void;
  onPrevClick: () => void;
  withNextButton: boolean;
  withPrevButton: boolean;
  courseId: number;
  courseTitle: string;
}

const SingleModule: FunctionComponent<ISingleModuleProps> = (props) => {
  const {
    title,
    video526p,
    video720p,
    video1080p,
    videoPoster,
    subtitle,
    id: moduleId,
    title: moduleTitle,
    captions,
  } = props.data;

  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const updatingUser = useRef(false);

  const {
    onBackClick,
    onNextClick,
    onPrevClick,
    withNextButton,
    withPrevButton,
    courseId,
    courseTitle,
  } = props;

  const userTracking = user?.tracking as ITracking | undefined;
  const userCourse = userTracking?.courses?.find((c) => c.id === courseId);
  const userCourseModule = userCourse?.modules?.find(
    (module) => module.id === moduleId
  );

  const [plyr, setPlyr] = useState<Plyr>();
  const plyrRef = useRef<Plyr>();

  const withVideo = !!video526p || !!video720p || !!video1080p;

  const plyrSource: PlyrProps['source'] = useMemo(() => {
    const sources = [];

    if (video526p) {
      sources.push({
        src: strapi.getStrapiMediaUrl(video526p.url),
        type: video526p.mime,
        size: 526,
      });
    }
    if (video720p) {
      sources.push({
        src: strapi.getStrapiMediaUrl(video720p.url),
        type: video720p.mime,
        size: 720,
      });
    }
    if (video1080p) {
      sources.push({
        src: strapi.getStrapiMediaUrl(video1080p.url),
        type: video1080p.mime,
        size: 1080,
      });
    }

    const poster = videoPoster
      ? getImageFormat({ file: videoPoster, format: 'large' })
      : VideoPoster;

    const tracks = createTracks(captions);

    return {
      type: 'video',
      sources,
      poster,
      tracks,
    };
  }, [video526p, video720p, video1080p, videoPoster, captions]);

  const plyrOptions: PlyrProps['options'] = useMemo(() => {
    return {
      quality: {
        default: 526,
        options: [526, 720, 1080],
      },
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'settings',
        'pip',
        'airplay',
        'fullscreen',
        'captions',
      ],
      settings: ['quality', 'captions'],
      ratio: '16:9',
      fullscreen: {
        enabled: true,
        fallback: true,
        iosNative: true,
      },
    };
  }, []);

  const isModuleCompletedByUser = userCourseModule?.completedAt !== undefined;

  const completeUserModuleChunk = useCallback(
    async (chunks: number[], persentage: number) => {
      if (!user || updatingUser.current) return;
      updatingUser.current = true;

      const updatedUser = await strapi.completeUserModuleChunk({
        user,
        courseId,
        moduleId,
        courseTitle,
        moduleTitle,
        completedChunks: chunks,
        completedPercentage: persentage,
        date: Date.now(),
      });

      updatingUser.current = false;
      updatedUser && dispatch(setUser(updatedUser));
    },
    [user, courseId, moduleId, courseTitle, moduleTitle, dispatch]
  );

  const completeUserModule = useCallback(async () => {
    if (!user || updatingUser.current) return;

    updatingUser.current = true;

    const updatedUser = await strapi.completeUserModule({
      user,
      courseId,
      moduleId,
      date: Date.now(),
    });

    updatingUser.current = false;
    updatedUser && dispatch(setUser(updatedUser));
  }, [user, courseId, moduleId, dispatch]);

  const onTimeUpdate = useCallback(() => {
    if (isModuleCompletedByUser || !plyr) return;

    const prevCompletedChunks = userCourseModule?.completedChunks || [];

    // Chunk === each ten seconds. Why we split seconds by ten?
    // To give opportunity fire several times before next chunk will start.
    const chunk = Math.floor(plyr.currentTime / 10);

    // If chunk already in array return
    if (prevCompletedChunks.includes(chunk)) return;

    // Add chunk to array and calculate percentage
    const completedChunks = [...prevCompletedChunks, chunk];
    const completedPersentage = Math.floor(
      (completedChunks.length * 100) / Math.floor(plyr.duration / 10)
    );
    completeUserModuleChunk(completedChunks, completedPersentage);
  }, [
    isModuleCompletedByUser,
    plyr,
    completeUserModuleChunk,
    userCourseModule,
  ]);

  // If user have required persentage(> 90) set the module completed
  useEffect(() => {
    if (
      isModuleCompletedByUser ||
      userCourseModule?.completedPercentage === undefined ||
      userCourseModule.completedPercentage < 90
    )
      return;
    completeUserModule();
  }, [
    userCourseModule?.completedPercentage,
    completeUserModule,
    isModuleCompletedByUser,
  ]);

  // Add listener to video time update
  useEffect(() => {
    plyrRef.current?.on('timeupdate', onTimeUpdate);

    return () => {
      plyrRef.current?.off('timeupdate', onTimeUpdate);
    };
  }, [plyr, onTimeUpdate]);

  const plyrRefCallback = useCallback((player: APITypes | null) => {
    // Unmounted
    if (plyrRef.current && player === null) {
      console.log('destroy');
      plyrRef.current.destroy();
      plyrRef.current = undefined;
      setPlyr(undefined);
      return;
    }
    // Mounted
    if (player && player.plyr?.source !== null && !plyrRef.current) {
      plyrRef.current = player.plyr as Plyr;
      setPlyr(player.plyr as Plyr);
      return;
    }
  }, []);

  if (!user) {
    return null;
  }

  return (
    <>
      <div className={styles.singleModule} style={{ zIndex: 3 }}>
        <div className={styles.singleModuleInner}>
          <div className={styles.header}>
            <h2 className={styles.title}>{title}</h2>
          </div>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          <div className={styles.navigation}>
            <button className={styles.backButton} onClick={onBackClick}>
              Return to course
            </button>
            <div className={styles.navigationRightSide}>
              <button
                onClick={onPrevClick}
                className={clsx(
                  styles.prevButton,
                  !withPrevButton && styles.buttonDisabled
                )}
                disabled={!withPrevButton}
              >
                previous
              </button>

              <button
                onClick={onNextClick}
                className={clsx(
                  styles.nextButton,
                  !withNextButton && styles.buttonDisabled
                )}
                disabled={!withNextButton}
              >
                next
              </button>
            </div>
          </div>
          {withVideo && (
            <div className={styles.videoWrapper}>
              <Plyr
                ref={plyrRefCallback}
                source={plyrSource}
                options={plyrOptions}
                crossOrigin="anonymus"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleModule;
