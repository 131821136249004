import { IFile } from 'types/generated/strapi';
import { parseLocale } from './parseLocale';

export function createTracks(source: IFile[] | undefined) {
  if (!source?.length) return undefined;

  return source.map((captionFile) => {
    const { name, url } = captionFile;
    const locale = parseLocale(name);

    return {
      kind: 'captions' as 'captions',
      label: locale?.[1] || 'Caption',
      srclang: locale?.[0],
      src: url,
    };
  });
}
