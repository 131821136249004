import { FunctionComponent, useMemo } from 'react';
import clsx from 'clsx';
import StrapiImage from 'components/shared/StrapiImage';
import { IImageAndTwoColumnText } from 'types/generated/strapi';
import styles from './index.module.css';

interface ImageAndTwoColumnTextProps {
  data: IImageAndTwoColumnText;
}

const ImageAndTwoColumnText: FunctionComponent<ImageAndTwoColumnTextProps> = (
  props
) => {
  const { title, textLeft, textRight, layout, link, image } = props.data;

  const layoutClass = useMemo(() => {
    return layout === 'ImageRight'
      ? styles.layoutImageRight
      : styles.layoutImageLeft;
  }, [layout]);

  return (
    <div className={styles.imageAndTwoColumnText}>
      <div className={styles.imageAndTwoColumnTextInner}>
        <div className={clsx([styles.columnImage, layoutClass])}>
          {/* Image */}
          <StrapiImage className={styles.image} image={image} format="medium" />
        </div>

        <div className={clsx([styles.columnText, layoutClass])}>
          {/* Title */}
          {title && <h2 className={styles.title}>{title}</h2>}

          {/* Text */}
          {(textLeft || textRight) && (
            <div className={styles.text}>
              <div className={styles.textLeft}>{textLeft}</div>
              <div className={styles.textRight}>{textRight}</div>
            </div>
          )}

          {/* Link */}
          {link && (
            <a className={styles.button} href={link.url} target="_top">
              {link.label}
            </a>
          )}
        </div>
      </div>

      {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
    </div>
  );
};

export default ImageAndTwoColumnText;
