import { FunctionComponent, useEffect } from 'react';
import exposureIframeHeight from 'utils/exposureIframeHeight';

const FallbackContent = () => (
  <>
    <h1>Adidas Community</h1>
    <p>Something goes wrong. Please try again later.</p>
  </>
);

// const ErrorContent = () => (
//   <div
//     style={{
//       display: 'grid',
//       placeContent: 'center',
//       width: '100vw',
//       height: '100vh',
//       textAlign: 'center',
//     }}
//   >
//     <h1>ERROR</h1>
//     <h4>Please reload the page to try again</h4>
//   </div>
// );

const PageError: FunctionComponent = () => {
  useEffect(() => {
    exposureIframeHeight();
  });

  return (
    // <ErrorContent />
    <FallbackContent />
  );
};

export default PageError;
