import { useEffect, useState } from 'react';
import debounce from 'just-debounce-it';

interface DocumentSize {
  width: number;
  height: number;
}

export function useResize(
  resizeFunction?: (size: DocumentSize) => void,
  debounceTime: number = 0
) {
  const [size, setSize] = useState<DocumentSize>({
    width: document.body.scrollWidth,
    height: document.body.scrollHeight,
  });

  const onResize = debounce(() => {
    setSize({
      width: document.body.scrollWidth,
      height: document.body.scrollHeight,
    });

    if (resizeFunction) resizeFunction(size);
  }, debounceTime);

  useEffect(() => {
    if (resizeFunction) resizeFunction(size);

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return [size];
}

export default useResize;
