import { FunctionComponent, useMemo } from 'react';

import { getImageFormat } from '@exposuredigital/strapi-helpers';

import { ITeaserGrid, IFile } from 'types/generated/strapi';

import styles from './index.module.css';

interface TeaserGridProps {
  data: ITeaserGrid;
}

const ImageBlock: FunctionComponent<{ image: IFile; index: number }> = (
  props
) => {
  const { image, index } = props;

  const imageUrl = useMemo(() => {
    return (
      getImageFormat({
        file: image,
        format: 'thumbnail',
        waterfall: true,
      }) || ''
    );
  }, [image]);

  return (
    <div
      style={{ backgroundImage: `url(${imageUrl})` }}
      className={styles[`blockImage${index + 1}`]}
    />
  );
};

const TeaserGrid: FunctionComponent<TeaserGridProps> = (props) => {
  const {
    images,
    textBlock1,
    textBlock2,
    textBlock3,
    textBlock4,
    textBlock1Link,
    textBlock2Link,
    textBlock3Link,
    textBlock4Link,
    title,
  } = props.data;

  const imageBlocks: IFile[] = useMemo(() => {
    const numImagesToDisplay = 12;

    return new Array(Math.ceil(numImagesToDisplay / images.length))
      .fill(images)
      .flat()
      .slice(0, numImagesToDisplay);
  }, [images]);

  return (
    <div className={styles.teaserGrid}>
      <div className={styles.teaserGridInner}>
        {/* Title */}
        <h2 className={styles.title}>{title}</h2>

        {/* Grid */}
        <div className={styles.grid}>
          {/* Text 1 */}
          <div className={styles.blockTitle1}>
            {textBlock1Link ? (
              <a
                className={styles.blockTitleInner}
                href={textBlock1Link}
                target="_top"
              >
                {textBlock1}
              </a>
            ) : (
              <div className={styles.blockTitleInner}>{textBlock1}</div>
            )}
          </div>

          {/* Text 2 */}
          <div className={styles.blockTitle2}>
            {textBlock2Link ? (
              <a
                className={styles.blockTitleInner}
                href={textBlock2Link}
                target="_top"
              >
                {textBlock2}
              </a>
            ) : (
              <div className={styles.blockTitleInner}>{textBlock2}</div>
            )}
          </div>

          {/* Text 3 */}
          <div className={styles.blockTitle3}>
            {textBlock3Link ? (
              <a
                className={styles.blockTitleInner}
                href={textBlock3Link}
                target="_top"
              >
                {textBlock3}
              </a>
            ) : (
              <div className={styles.blockTitleInner}>{textBlock3}</div>
            )}
          </div>

          {/* Text 4 */}
          <div className={styles.blockTitle4}>
            {textBlock4Link ? (
              <a
                className={styles.blockTitleInner}
                href={textBlock4Link}
                target="_top"
              >
                {textBlock4}
              </a>
            ) : (
              <div className={styles.blockTitleInner}>{textBlock4}</div>
            )}
          </div>

          {/* Images */}
          {imageBlocks.map((image, index) => (
            <ImageBlock key={index} image={image} index={index} />
          ))}
        </div>
      </div>

      {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
    </div>
  );
};

export default TeaserGrid;
