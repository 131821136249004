import { FunctionComponent, useEffect, useState } from 'react';
import * as strapi from 'services/strapi';
import exposureIframeHeight from 'utils/exposureIframeHeight';
import SingleImage from 'components/sections/SingleImage';
import OversizedTitle from 'components/sections/OversizedTitle';
import ArchitectsList from 'components/sections/ArchitectsList';
import PageError from 'pages/Error';
import { IPageArchitects } from 'types/generated/strapi';
import { PageLoading } from 'components/shared/PageLoading';

const PageHome: FunctionComponent = () => {
  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);
  const [pageData, setPageData] = useState<IPageArchitects>();

  useEffect(() => {
    (async () => {
      const pageData = await strapi.getPageArchitects();

      setPageData(pageData);
      setPageIsLoading(false);
      exposureIframeHeight();
    })();
  }, []);

  const { headerImage, architectsTitle, architectsList } = { ...pageData };

  if (!pageIsLoading && !pageData) return <PageError />;

  return pageIsLoading ? (
    <PageLoading />
  ) : (
    <>
      {headerImage && <SingleImage data={headerImage} />}
      {architectsTitle && <OversizedTitle data={architectsTitle} />}
      {architectsList && <ArchitectsList data={architectsList} />}
    </>
  );
};

export default PageHome;
