import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { IHivebriteUser } from 'types/generated/strapi';

export interface UserState {
  value: IHivebriteUser | null;
}

const initialState: UserState = {
  value: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IHivebriteUser>) => {
      state.value = action.payload;
    },
    clearUser: (state) => {
      state.value = null;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export const getUser = (state: RootState) => state.user.value;
export const userIsLoggedIn = (state: RootState) => !!state.user.value;

export default userSlice.reducer;
