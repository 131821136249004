import { FunctionComponent } from 'react';

import {
  IAdditionalContent,
  IAdditionalContentItem,
} from 'types/generated/strapi';

import styles from './index.module.css';
import { ReactComponent as PlayIcon } from 'assets/svg/play.svg';
import { ReactComponent as DocumentIcon } from 'assets/svg/document.svg';
import { ReactComponent as LinkIcon } from 'assets/svg/link.svg';

import clsx from 'clsx';

interface IAdditionalContentItemProps {
  data: IAdditionalContentItem;
  onAdditionalContentItemClick?: (a: IAdditionalContentItem) => void;
}

const AdditionalContentItem: FunctionComponent<IAdditionalContentItemProps> = (
  props
) => {
  const {
    data: {
      icon,
      title,
      textField,
      underline,
      link,
      file,
      subtitle,
      youTubeLink,
    },
    onAdditionalContentItemClick,
  } = props;

  return (
    <div className={styles.row}>
      <div className={styles.column1}>
        {icon === 'video' && <PlayIcon className={styles.icon} />}
        {icon === 'document' && <DocumentIcon className={styles.icon} />}
        {icon === 'link' && <LinkIcon className={styles.icon} />}
      </div>
      <div className={styles.column2}>
        {/* Link */}
        {link && (
          <a
            className={clsx(styles.name, underline && styles.nameUnderline)}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            {title}
          </a>
        )}
        {/* File */}
        {!!file && (
          <a
            className={clsx(styles.name, underline && styles.nameUnderline)}
            href={file.url}
            download
            target="_blank"
            rel="noreferrer"
          >
            {title}
          </a>
        )}
        {/* Youtube Link */}
        {youTubeLink && (
          <button
            className={clsx(styles.button, underline && styles.nameUnderline)}
            onClick={() => onAdditionalContentItemClick?.(props.data)}
          >
            {title}
          </button>
        )}
        {/* Just title */}
        {!link && !file && !youTubeLink && (
          <div
            className={clsx(
              !underline && styles.name,
              underline && styles.nameUnderline
            )}
          >
            {title}
          </div>
        )}
        {/* Subtitle */}
        {subtitle && (
          <div className={clsx(styles.nameSubtitle)}>{subtitle}</div>
        )}
      </div>
      <div className={styles.column3}>{textField}</div>
    </div>
  );
};

interface IAdditionalContentProps {
  data: IAdditionalContent;
  onAdditionalContentItemClick?: (a: IAdditionalContentItem) => void;
}
const AdditionalContent: FunctionComponent<IAdditionalContentProps> = (
  props
) => {
  const {
    data: { title, subtitle, items },
    onAdditionalContentItemClick,
  } = props;

  return (
    <div className={styles.additionalContent}>
      <div className={styles.additionalContentInner}>
        <div className={styles.header}>
          {title && <h2 className={styles.title}>{title}</h2>}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
        {items?.length > 0 && (
          <div className={styles.tableWrapper}>
            <div className={styles.table}>
              {items.map((additionalContent) => (
                <AdditionalContentItem
                  key={additionalContent.id}
                  data={additionalContent}
                  onAdditionalContentItemClick={onAdditionalContentItemClick}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdditionalContent;
