/** Executes a callback when a message with the user is received */
const jwtReady = (callback: (jwt: string) => void) => {
  window.addEventListener(
    'message',
    (event) => {
      if (event.data.call === 'hivebriteJwt') {
        callback(event.data.value as string);
      }
    },
    false
  );
};

export default jwtReady;
