/** Notifies the parent that the iframe request scroll to join user a course */
const exposureIframeJoinUser = () => {
  window.parent.postMessage(
    {
      call: 'exposureIframeJoinUser',
    },
    '*'
  );
};

export default exposureIframeJoinUser;
