import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import * as strapi from 'services/strapi';
import truncateString from 'utils/truncateString';
import StrapiImage from 'components/shared/StrapiImage';
import { ICourse, ICoursesList } from 'types/generated/strapi';
import styles from './index.module.css';
import { prettyBreak } from 'pretty-break';

interface CoursesListProps {
  data: ICoursesList;
}

interface CourseBlockProps {
  course: ICourse;
}

const isCourse = (course?: ICourse): course is ICourse => {
  return !!course;
};

const startOfToday = new Date(new Date().setHours(0, 0, 0, 0));

const CourseBlock: FunctionComponent<CourseBlockProps> = (props) => {
  const { course } = props;
  const { launchDate, link, overview, overviewShort, thumbnail, title } =
    course;

  const overviewText = useMemo(() => {
    return (
      overviewShort ||
      truncateString(overview, {
        length: 50,
      })
    );
  }, [overviewShort, overview]);

  const isLaunched = useMemo(() => {
    if (!launchDate) return false;
    return new Date(launchDate) < startOfToday;
  }, [launchDate]);

  const launchDateString = useMemo(() => {
    if (!launchDate) return '';

    return Intl.DateTimeFormat('en-US', {
      month: 'long',
      year: 'numeric',
    }).format(new Date(launchDate));
  }, [launchDate]);

  const ImageWrapper: FunctionComponent<{ className?: string }> = (props) => {
    return link && isLaunched ? (
      <a {...props} href={link.url} target="_top">
        {props.children}
      </a>
    ) : (
      <div {...props}>{props.children}</div>
    );
  };

  return (
    <div
      className={clsx([styles.course, !isLaunched && styles.courseUpcoming])}
    >
      {/* Image */}
      <ImageWrapper className={styles.imageWrapper}>
        <StrapiImage
          className={styles.image}
          image={thumbnail}
          format="small"
        />
      </ImageWrapper>

      {/* Title */}
      <dt className={styles.courseTitle}>{title}</dt>

      {/* Overview */}
      <dd className={styles.courseOverview}>{overviewText}</dd>

      {/* Link */}
      {link && isLaunched && (
        <a className={styles.courseLink} href={link.url} target="_top">
          {link.label}
        </a>
      )}

      {/* Coming soon */}
      {launchDate && !isLaunched && launchDateString && (
        <div className={styles.comingSoon}>Coming {launchDateString}</div>
      )}
    </div>
  );
};

const CoursesList: FunctionComponent<CoursesListProps> = (props) => {
  const { courses, title } = {
    ...props.data,
  };

  const [coursesData, setCoursesData] = useState<ICourse[]>(courses);

  useEffect(() => {
    // pretty-break
    const titles = document.getElementsByClassName(styles.courseTitle);
    for (var i = 0; i < titles.length; i++) {
      prettyBreak(titles[i]);
    }

    (async () => {
      const coursesData = await strapi.getCourses();

      const coursesToUse = courses
        .map((course) =>
          coursesData?.find((fetchedCourse) => fetchedCourse.id === course.id)
        )
        .filter(isCourse);

      if (coursesToUse) setCoursesData(coursesToUse);
    })();
  }, [courses]);

  return (
    <div className={styles.coursesList}>
      <div className={styles.coursesListInner}>
        {/* Title */}
        <h2 className={styles.title}>{title}</h2>

        {/* Courses */}
        <dl className={styles.grid}>
          {coursesData.map((course) => (
            <CourseBlock key={course.id} course={course} />
          ))}
        </dl>
      </div>

      {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
    </div>
  );
};

export default CoursesList;
