import { ITrackingCourseModule } from './../types/trackingCourseModule';
import { ITracking } from 'types/tracking';
import { ITrackingCourse } from 'types/trackingCourse';

export const createTracking = ({
  courses = [],
}: {
  courses: ITrackingCourse[];
}): ITracking => ({
  courses,
});

export const createTrackingCourse = ({
  id,
  title,
  completedAt,
  startedAt,
  modules = [],
}: {
  id: number;
  title: string;
  completedAt?: number;
  startedAt?: number;
  modules: ITrackingCourseModule[];
}): ITrackingCourse => ({ id, title, completedAt, startedAt, modules });

export const createTrackingCourseModule = ({
  id,
  title,
  completedAt,
  startedAt,
  completedChunks = [],
  completedPercentage = 0,
}: {
  id: number;
  title: string;
  completedAt?: number;
  startedAt?: number;
  completedChunks: number[];
  completedPercentage: number;
}): ITrackingCourseModule => ({
  id,
  title,
  startedAt,
  completedAt,
  completedPercentage,
  completedChunks,
});
