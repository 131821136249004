import { FunctionComponent, useMemo } from 'react';
import clsx from 'clsx';
import { getImageFormat } from '@exposuredigital/strapi-helpers';

import { getStrapiMediaUrl } from 'services/strapi';

import { userIsLoggedIn } from 'redux/userSlice';
import { useAppSelector } from 'hooks';

import IframeLink from 'components/shared/IframeLink';

import { ITextOverImage } from 'types/generated/strapi';

import styles from './index.module.css';

interface TextOverImageProps {
  data: ITextOverImage;
  showCreateAccountButton?: boolean;
}

const TextOverImage: FunctionComponent<TextOverImageProps> = (props) => {
  const { showCreateAccountButton } = props;
  const { backgroundImage, textColor, buttonLink, text, title } = {
    ...props.data,
  };

  const isLoggedIn = useAppSelector(userIsLoggedIn);

  const imageUrl = useMemo(() => {
    return (
      getImageFormat({
        file: backgroundImage,
        format: 'xlarge',
        waterfall: true,
      }) || ''
    );
  }, [backgroundImage]);

  const imageFullPath = useMemo(() => {
    return getStrapiMediaUrl(imageUrl);
  }, [imageUrl]);

  const textColorClass = useMemo(() => {
    return textColor === 'Black' ? styles.darkText : styles.lightText;
  }, [textColor]);

  return (
    <div
      className={clsx([styles.textOverImage, textColorClass])}
      style={{ backgroundImage: `url(${imageFullPath})` }}
    >
      <div className={styles.textOverImageInner}>
        {/* Title */}
        <h2 className={styles.title}>{title}</h2>

        {/* Text */}
        <div className={styles.text}>{text}</div>

        {/* Buttons */}
        <div className={styles.buttons}>
          {showCreateAccountButton && !isLoggedIn && (
            <IframeLink
              className={clsx([styles.button, styles.accountButton])}
              url="https://community.adidas.com/signup"
              label="Join us"
            />
          )}
          {buttonLink && (
            <a
              className={clsx([styles.button, styles.linkButton])}
              href={buttonLink.url}
              target="_top"
            >
              {buttonLink.label}
            </a>
          )}
        </div>
      </div>

      {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
    </div>
  );
};

export default TextOverImage;
