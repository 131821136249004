import { FunctionComponent, useMemo } from 'react';

import Plyr, { PlyrProps } from 'plyr-react';
import 'assets/css/plyr/plyr.css';

import { IStream } from 'types/stream';

import styles from './index.module.css';
import { useAppSelector } from 'hooks';
import { getUser } from 'redux/userSlice';

interface IStreamProps {
  data: IStream;
  onBackClick: () => void;
}

const Stream: FunctionComponent<IStreamProps> = (props) => {
  const {
    data: { youTubeLink, title },
    onBackClick,
  } = props;

  const user = useAppSelector(getUser);

  const plyrSource: PlyrProps['source'] = useMemo(() => {
    return {
      type: 'video',
      sources: [
        {
          src: youTubeLink,
          provider: 'youtube',
        },
      ],
    };
  }, [youTubeLink]);

  const plyrOptions: PlyrProps['options'] = useMemo(() => {
    return {
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'settings',
        'pip',
        'airplay',
        'fullscreen',
      ],
      settings: ['quality'],
      ratio: '16:9',
      fullscreen: {
        enabled: true,
        fallback: true,
        iosNative: true,
      },
    };
  }, []);

  if (!user || !youTubeLink) {
    return null;
  }

  return (
    <>
      <div className={styles.stream} style={{ zIndex: 3 }}>
        <div className={styles.streamInner}>
          <div className={styles.header}>
            <h2 className={styles.title}>{title}</h2>
          </div>
          <div className={styles.navigation}>
            <button className={styles.backButton} onClick={onBackClick}>
              Return to course
            </button>
          </div>
          <div className={styles.videoWrapper}>
            <Plyr source={plyrSource} options={plyrOptions} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Stream;
