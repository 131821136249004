import { FunctionComponent, MouseEvent, useCallback } from 'react';

import exposureIframeNavigate from 'utils/exposureIframeNavigate';

interface IframeLinkProps {
  url: string;
  label: string;
  className?: string;
}

const IframeLink: FunctionComponent<IframeLinkProps> = (props) => {
  const { url, label, className } = props;

  const onClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      exposureIframeNavigate(url);
    },
    [url]
  );

  return (
    <button className={className} onClick={(event) => onClick(event)}>
      {label}
    </button>
  );
};

export default IframeLink;
