import locales from 'assets/data/locales.json';

export const parseLocale = (name: string): null | [string, string] => {
  let result: null | [string, string] = null;

  // Too short for locale plus name
  if (name.length < 3) return result;

  // Locale can be 2 chars(en, es, fr) or 5 chars(en-gb, en-us).
  // We will try to compare with 5 first and after with 2
  const firstFiveChars = name.toLocaleLowerCase().substring(0, 5);
  const firstTwoChars = name.toLocaleLowerCase().substring(0, 2);

  // First parse by 5
  (Object.keys(locales) as Array<keyof typeof locales>).forEach(
    (localeName) => {
      if (result || localeName.length < 5) return;
      if (firstFiveChars === localeName)
        result = [localeName, locales[localeName]];
    }
  );

  if (result) return result;

  // Second parse by 2
  (Object.keys(locales) as Array<keyof typeof locales>).forEach(
    (localeName) => {
      if (result || localeName.length > 2) return;
      if (firstTwoChars === localeName)
        result = [localeName, locales[localeName]];
    }
  );

  return result;
};
